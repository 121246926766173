import {
  AfterViewInit,
  Component,
  DoCheck,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { NotificationService } from 'src/app/services/notification.service';
import { ToastType } from "../../enums";

@Component({
  selector: "autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
  // tslint:disable-next-line: use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, DoCheck
{
  @Input()
  set elements(elements: any[]) {
    this._elements = elements;
    if (this.readOnly) this.setInputUsingValueProperty(this.value);
  }

  constructor(
    private injector: Injector,
    private notificationService: NotificationService
  ) {}
  private onChange: any;
  private onTouch: any;
  isDisabled = false;
  readOnly = false;
  isLoading = false;

  autoCompleteControl = new FormControl();

  ngControl: NgControl;

  value: any;
  items: any[] = [];
  _elements: any[] = [];

  @Input() loadElements: (filter: string) => Observable<any> = null;
  @Input() placeholder = "pls set placeholder @input :)";
  @Input() valueProperty = "id";
  @Input() initValues = false;
  @Input() displayWith: (e: any) => string = (_) => "id";

  ngOnInit() {
    // prevent  NgControl -> FormControlName -> ValueAccessor -> CustomValueAccessor -> NgControl circular dependency
    this.ngControl = this.injector.get<NgControl>(NgControl);

    this.autoCompleteControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        if (this.onTouch) {
          this.onTouch();
        }
        if (!this.readOnly) this.emitNewTextValue(value ? value : "");
      });

    if (this.initValues) {
      this.getElements(null);
    }
  }

  ngAfterViewInit() {
    if (this.ngControl.value) {
      // HACK: ExpressionChangedAfterItHasBeenCheckedError
      Promise.resolve(null).then(() => {
        this._onSelectionChanged(this.ngControl.value);
      });
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this._onSelectionChanged(event.option.value);
  }

  _onSelectionChanged(value: any) {
    this.readOnly = true;
    const valueSelected = value;
    this.setInputUsingValueProperty(valueSelected);
    this.value = valueSelected;
    if (this.onChange) this.onChange(valueSelected);
    this.autoCompleteControl.setValue(value);
  }

  setInputUsingValueProperty(value: any) {
    const element = (this._elements ? this._elements : []).find(
      (e) => e[this.valueProperty] === value
    );
    if (element) {
      this.autoCompleteControl.setValue(this.displayWith(element));
    }
  }

  emitNewTextValue(value: any) {
    const autoCompleteValue = value ? value : this.autoCompleteControl.value;
    // const autoCompleteValue = this.inputValue;
    if (autoCompleteValue && typeof autoCompleteValue !== "object") {
      this.getElements(autoCompleteValue);
    }
  }

  getElements(filter: string, loading: boolean = true) {
    if (this.loadElements) {
      this.isLoading = loading;
      this.loadElements(filter).subscribe(
        (items: any[]) => {
          this.items = items;
          this.isLoading = false;
        },
        (_) => {
          this.notificationService.toast(
            "C'è stato un problema durante il caricamento dei dati",
            ToastType.error,
            "Errore"
          );
          this.isLoading = false;
        }
      );
    }
  }

  cleanValue() {
    this.autoCompleteControl.setValue(null);
    this.value = null;
    if (this.onChange) this.onChange(null);
    this.readOnly = false;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.cleanValue();
      return;
    }

    this.readOnly = true;
    // if entire object passed set obj[valueproperty] as value and obj[displayProperty] as textbox value
    if (typeof obj === "object") {
      this.elements = [obj];
      this.value = obj[this.valueProperty];
      this.setInputUsingValueProperty(obj[this.valueProperty]);
    } else {
      // if only valueProperty is passed call findElement(Output) in order to receive the element to set in the elements(Input) array
      // this.findElement.emit(obj);
      this.value = obj;
      this.setInputUsingValueProperty(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.isDisabled
      ? this.autoCompleteControl.disable()
      : this.autoCompleteControl.enable();
  }

  ngDoCheck(): void {
    if (!this.ngControl) return;
    this.ngControl.pristine
      ? this.autoCompleteControl.markAsPristine()
      : this.autoCompleteControl.markAsDirty();
    this.ngControl.touched
      ? this.autoCompleteControl.markAsTouched()
      : this.autoCompleteControl.markAsUntouched();
  }
}
