/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InvoiceLineRefundDto } from '../models/invoice-line-refund-dto';
import { InvoiceLineRefundUpdateDto } from '../models/invoice-line-refund-update-dto';

@Injectable({
  providedIn: 'root',
})
export class InvoiceLineRefundService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation invoiceLineRefundGetAll
   */
  static readonly InvoiceLineRefundGetAllPath = '/api/invoiceLinesRefunds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    InvoiceId?: number;
  }): Observable<StrictHttpResponse<Array<InvoiceLineRefundDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceLineRefundService.InvoiceLineRefundGetAllPath, 'get');
    if (params) {
      rb.query('InvoiceId', params.InvoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceLineRefundDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    InvoiceId?: number;
  }): Observable<Array<InvoiceLineRefundDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceLineRefundDto>>) => r.body as Array<InvoiceLineRefundDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    InvoiceId?: number;
  }): Observable<StrictHttpResponse<Array<InvoiceLineRefundDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceLineRefundService.InvoiceLineRefundGetAllPath, 'get');
    if (params) {
      rb.query('InvoiceId', params.InvoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceLineRefundDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    InvoiceId?: number;
  }): Observable<Array<InvoiceLineRefundDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceLineRefundDto>>) => r.body as Array<InvoiceLineRefundDto>)
    );
  }

  /**
   * Path part for operation invoiceLineRefundBulkUpsert
   */
  static readonly InvoiceLineRefundBulkUpsertPath = '/api/invoiceLinesRefunds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpsert$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkUpsert$Plain$Response(params?: {
    body?: Array<InvoiceLineRefundUpdateDto>
  }): Observable<StrictHttpResponse<Array<InvoiceLineRefundDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceLineRefundService.InvoiceLineRefundBulkUpsertPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceLineRefundDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpsert$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkUpsert$Plain(params?: {
    body?: Array<InvoiceLineRefundUpdateDto>
  }): Observable<Array<InvoiceLineRefundDto>> {

    return this.bulkUpsert$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceLineRefundDto>>) => r.body as Array<InvoiceLineRefundDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpsert()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkUpsert$Response(params?: {
    body?: Array<InvoiceLineRefundUpdateDto>
  }): Observable<StrictHttpResponse<Array<InvoiceLineRefundDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceLineRefundService.InvoiceLineRefundBulkUpsertPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceLineRefundDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpsert$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkUpsert(params?: {
    body?: Array<InvoiceLineRefundUpdateDto>
  }): Observable<Array<InvoiceLineRefundDto>> {

    return this.bulkUpsert$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceLineRefundDto>>) => r.body as Array<InvoiceLineRefundDto>)
    );
  }

}
