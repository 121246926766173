/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectCreationDto } from '../models/project-creation-dto';
import { ProjectDto } from '../models/project-dto';
import { ProjectStatus } from '../models/project-status';
import { ProjectUpdateDto } from '../models/project-update-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectGetAll
   */
  static readonly ProjectGetAllPath = '/api/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    CustomerId?: number;
    SubCategoryId?: number;
    Status?: ProjectStatus;
    Search?: string;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<StrictHttpResponse<Array<ProjectDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('SubCategoryId', params.SubCategoryId, {});
      rb.query('Status', params.Status, {});
      rb.query('Search', params.Search, {});
      rb.query('Page', params.Page, {});
      rb.query('Size', params.Size, {});
      rb.query('Sort', params.Sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    CustomerId?: number;
    SubCategoryId?: number;
    Status?: ProjectStatus;
    Search?: string;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<Array<ProjectDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectDto>>) => r.body as Array<ProjectDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    CustomerId?: number;
    SubCategoryId?: number;
    Status?: ProjectStatus;
    Search?: string;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<StrictHttpResponse<Array<ProjectDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('SubCategoryId', params.SubCategoryId, {});
      rb.query('Status', params.Status, {});
      rb.query('Search', params.Search, {});
      rb.query('Page', params.Page, {});
      rb.query('Size', params.Size, {});
      rb.query('Sort', params.Sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    CustomerId?: number;
    SubCategoryId?: number;
    Status?: ProjectStatus;
    Search?: string;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<Array<ProjectDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectDto>>) => r.body as Array<ProjectDto>)
    );
  }

  /**
   * Path part for operation projectCreate
   */
  static readonly ProjectCreatePath = '/api/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: {
    body?: ProjectCreationDto
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: {
    body?: ProjectCreationDto
  }): Observable<ProjectDto> {

    return this.create$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: {
    body?: ProjectCreationDto
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: {
    body?: ProjectCreationDto
  }): Observable<ProjectDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectGetById
   */
  static readonly ProjectGetByIdPath = '/api/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: {
    id: number;
  }): Observable<ProjectDto> {

    return this.getById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: {
    id: number;
  }): Observable<ProjectDto> {

    return this.getById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectUpdateById
   */
  static readonly ProjectUpdateByIdPath = '/api/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain$Response(params: {
    id: number;
    body?: ProjectUpdateDto
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectUpdateByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain(params: {
    id: number;
    body?: ProjectUpdateDto
  }): Observable<ProjectDto> {

    return this.updateById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Response(params: {
    id: number;
    body?: ProjectUpdateDto
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectUpdateByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById(params: {
    id: number;
    body?: ProjectUpdateDto
  }): Observable<ProjectDto> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectDeleteById
   */
  static readonly ProjectDeleteByIdPath = '/api/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ProjectDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: number;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
