/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { File } from '../models/file';
import { ReportDto } from '../models/report-dto';
import { ReportExportType } from '../models/report-export-type';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reportReport
   */
  static readonly ReportReportPath = '/api/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `report$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  report$Plain$Response(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
  }): Observable<StrictHttpResponse<ReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportReportPath, 'get');
    if (params) {
      rb.query('ProjectId', params.ProjectId, {});
      rb.query('From', params.From, {});
      rb.query('To', params.To, {});
      rb.query('FiscalYear', params.FiscalYear, {});
      rb.query('ProjectOrId', params.ProjectOrId, {});
      rb.query('ProjectOrTypeCode', params.ProjectOrTypeCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `report$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  report$Plain(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
  }): Observable<ReportDto> {

    return this.report$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportDto>) => r.body as ReportDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `report()` instead.
   *
   * This method doesn't expect any request body.
   */
  report$Response(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
  }): Observable<StrictHttpResponse<ReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportReportPath, 'get');
    if (params) {
      rb.query('ProjectId', params.ProjectId, {});
      rb.query('From', params.From, {});
      rb.query('To', params.To, {});
      rb.query('FiscalYear', params.FiscalYear, {});
      rb.query('ProjectOrId', params.ProjectOrId, {});
      rb.query('ProjectOrTypeCode', params.ProjectOrTypeCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `report$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  report(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
  }): Observable<ReportDto> {

    return this.report$Response(params).pipe(
      map((r: StrictHttpResponse<ReportDto>) => r.body as ReportDto)
    );
  }

  /**
   * Path part for operation reportExport
   */
  static readonly ReportExportPath = '/api/report/xls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export()` instead.
   *
   * This method doesn't expect any request body.
   */
  export$Response(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
    reportExportType?: ReportExportType;
  }): Observable<StrictHttpResponse<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportExportPath, 'get');
    if (params) {
      rb.query('ProjectId', params.ProjectId, {});
      rb.query('From', params.From, {});
      rb.query('To', params.To, {});
      rb.query('FiscalYear', params.FiscalYear, {});
      rb.query('ProjectOrId', params.ProjectOrId, {});
      rb.query('ProjectOrTypeCode', params.ProjectOrTypeCode, {});
      rb.query('reportExportType', params.reportExportType, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export(params?: {
    ProjectId?: number;
    From?: string;
    To?: string;
    FiscalYear?: number;
    ProjectOrId?: number;
    ProjectOrTypeCode?: string;
    reportExportType?: ReportExportType;
  }): Observable<File> {

    return this.export$Response(params).pipe(
      map((r: StrictHttpResponse<File>) => r.body as File)
    );
  }

  /**
   * Path part for operation reportExportPdf
   */
  static readonly ReportExportPdfPath = '/api/report/pdf/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPdf$Response(params: {
    projectId: number;
  }): Observable<StrictHttpResponse<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportExportPdfPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPdf(params: {
    projectId: number;
  }): Observable<File> {

    return this.exportPdf$Response(params).pipe(
      map((r: StrictHttpResponse<File>) => r.body as File)
    );
  }

  /**
   * Path part for operation reportExportPdftest
   */
  static readonly ReportExportPdftestPath = '/api/report/pdftest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPdftest()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPdftest$Response(params?: {
  }): Observable<StrictHttpResponse<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ReportExportPdftestPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportPdftest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPdftest(params?: {
  }): Observable<File> {

    return this.exportPdftest$Response(params).pipe(
      map((r: StrictHttpResponse<File>) => r.body as File)
    );
  }

}
