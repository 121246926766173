import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenDto } from 'src/app/FlammeServices/models';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {

  constructor(private sessionService: SessionService) {
  }

  canActivate(): Observable<boolean> {
    return this.sessionService.currentUser$.pipe(map((tokenDto: TokenDto) => {
      if (tokenDto) return true;
      else {
        console.error("You're not logged in!");
        return false;
      }
    }));
  }
}
