/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StandardHourlyCostDto } from '../models/standard-hourly-cost-dto';

@Injectable({
  providedIn: 'root',
})
export class StandardHourlyCostService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation standardHourlyCostGetAll
   */
  static readonly StandardHourlyCostGetAllPath = '/api/standard-hourly-cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<StandardHourlyCostDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StandardHourlyCostDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
  }): Observable<Array<StandardHourlyCostDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StandardHourlyCostDto>>) => r.body as Array<StandardHourlyCostDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<StandardHourlyCostDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StandardHourlyCostDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
  }): Observable<Array<StandardHourlyCostDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StandardHourlyCostDto>>) => r.body as Array<StandardHourlyCostDto>)
    );
  }

  /**
   * Path part for operation standardHourlyCostDeleteByYear
   */
  static readonly StandardHourlyCostDeleteByYearPath = '/api/standard-hourly-cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByYear$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByYear$Plain$Response(params?: {
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostDeleteByYearPath, 'delete');
    if (params) {
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByYear$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByYear$Plain(params?: {
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.deleteByYear$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByYear$Response(params?: {
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostDeleteByYearPath, 'delete');
    if (params) {
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByYear(params?: {
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.deleteByYear$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostGetByYear
   */
  static readonly StandardHourlyCostGetByYearPath = '/api/standard-hourly-cost/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByYear$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByYear$Plain$Response(params: {
    year: number;
  }): Observable<StrictHttpResponse<Array<StandardHourlyCostDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostGetByYearPath, 'get');
    if (params) {
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StandardHourlyCostDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByYear$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByYear$Plain(params: {
    year: number;
  }): Observable<Array<StandardHourlyCostDto>> {

    return this.getByYear$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StandardHourlyCostDto>>) => r.body as Array<StandardHourlyCostDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByYear$Response(params: {
    year: number;
  }): Observable<StrictHttpResponse<Array<StandardHourlyCostDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostGetByYearPath, 'get');
    if (params) {
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StandardHourlyCostDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByYear(params: {
    year: number;
  }): Observable<Array<StandardHourlyCostDto>> {

    return this.getByYear$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StandardHourlyCostDto>>) => r.body as Array<StandardHourlyCostDto>)
    );
  }

  /**
   * Path part for operation standardHourlyCostCreateNewStandardHourlyCost
   */
  static readonly StandardHourlyCostCreateNewStandardHourlyCostPath = '/api/standard-hourly-cost/newCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewStandardHourlyCost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createNewStandardHourlyCost$Plain$Response(params?: {
    high?: number;
    medium?: number;
    low?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostCreateNewStandardHourlyCostPath, 'put');
    if (params) {
      rb.query('high', params.high, {});
      rb.query('medium', params.medium, {});
      rb.query('low', params.low, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNewStandardHourlyCost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createNewStandardHourlyCost$Plain(params?: {
    high?: number;
    medium?: number;
    low?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.createNewStandardHourlyCost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewStandardHourlyCost()` instead.
   *
   * This method doesn't expect any request body.
   */
  createNewStandardHourlyCost$Response(params?: {
    high?: number;
    medium?: number;
    low?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostCreateNewStandardHourlyCostPath, 'put');
    if (params) {
      rb.query('high', params.high, {});
      rb.query('medium', params.medium, {});
      rb.query('low', params.low, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNewStandardHourlyCost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createNewStandardHourlyCost(params?: {
    high?: number;
    medium?: number;
    low?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.createNewStandardHourlyCost$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostUpdateYearCategory
   */
  static readonly StandardHourlyCostUpdateYearCategoryPath = '/api/standard-hourly-cost/yearCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateYearCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYearCategory$Plain$Response(params?: {
    year?: number;
    oldYear?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateYearCategoryPath, 'put');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('oldYear', params.oldYear, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateYearCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYearCategory$Plain(params?: {
    year?: number;
    oldYear?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateYearCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateYearCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYearCategory$Response(params?: {
    year?: number;
    oldYear?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateYearCategoryPath, 'put');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('oldYear', params.oldYear, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateYearCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateYearCategory(params?: {
    year?: number;
    oldYear?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateYearCategory$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostUpdateHighCategory
   */
  static readonly StandardHourlyCostUpdateHighCategoryPath = '/api/standard-hourly-cost/highCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHighCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateHighCategory$Plain$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateHighCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateHighCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateHighCategory$Plain(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateHighCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHighCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateHighCategory$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateHighCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateHighCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateHighCategory(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateHighCategory$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostUpdateMediumCategory
   */
  static readonly StandardHourlyCostUpdateMediumCategoryPath = '/api/standard-hourly-cost/mediumCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMediumCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMediumCategory$Plain$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateMediumCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMediumCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMediumCategory$Plain(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateMediumCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMediumCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMediumCategory$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateMediumCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMediumCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMediumCategory(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateMediumCategory$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostUpdateLowCategory
   */
  static readonly StandardHourlyCostUpdateLowCategoryPath = '/api/standard-hourly-cost/lowCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLowCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLowCategory$Plain$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateLowCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLowCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLowCategory$Plain(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateLowCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLowCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLowCategory$Response(params?: {
    input?: number;
    year?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostUpdateLowCategoryPath, 'put');
    if (params) {
      rb.query('input', params.input, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLowCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLowCategory(params?: {
    input?: number;
    year?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.updateLowCategory$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * Path part for operation standardHourlyCostDeleteEntity
   */
  static readonly StandardHourlyCostDeleteEntityPath = '/api/standard-hourly-cost/entity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntity$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntity$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostDeleteEntityPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEntity$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntity$Plain(params?: {
    id?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.deleteEntity$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntity$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<StandardHourlyCostDto>> {

    const rb = new RequestBuilder(this.rootUrl, StandardHourlyCostService.StandardHourlyCostDeleteEntityPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StandardHourlyCostDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntity(params?: {
    id?: number;
  }): Observable<StandardHourlyCostDto> {

    return this.deleteEntity$Response(params).pipe(
      map((r: StrictHttpResponse<StandardHourlyCostDto>) => r.body as StandardHourlyCostDto)
    );
  }

}
