import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "bss-message-dialog",
  templateUrl: "./message.dialog.html",
  styleUrls: ["./message.dialog.scss"]
})
export class MessageDialogComponent {
  color = "primary";

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
