/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseInvoiceLineCreationDto } from '../models/base-invoice-line-creation-dto';
import { InvoiceCreationDto } from '../models/invoice-creation-dto';
import { InvoiceDto } from '../models/invoice-dto';
import { InvoiceStatus } from '../models/invoice-status';
import { InvoiceStatusUpdateDto } from '../models/invoice-status-update-dto';
import { InvoiceUpdateDto } from '../models/invoice-update-dto';
import { ProjectDto } from '../models/project-dto';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation invoiceGetAll
   */
  static readonly InvoiceGetAllPath = '/api/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    CustomerId?: number;
    ProjectId?: number;
    Search?: string;
    TypeDocument?: string;
    From?: string;
    To?: string;
    RefundTypeId?: number;
    Status?: InvoiceStatus;
  }): Observable<StrictHttpResponse<Array<InvoiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('ProjectId', params.ProjectId, {});
      rb.query('Search', params.Search, {});
      rb.query('TypeDocument', params.TypeDocument, {});
      rb.query('From', params.From, {});
      rb.query('To', params.To, {});
      rb.query('RefundTypeId', params.RefundTypeId, {});
      rb.query('Status', params.Status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    CustomerId?: number;
    ProjectId?: number;
    Search?: string;
    TypeDocument?: string;
    From?: string;
    To?: string;
    RefundTypeId?: number;
    Status?: InvoiceStatus;
  }): Observable<Array<InvoiceDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceDto>>) => r.body as Array<InvoiceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    CustomerId?: number;
    ProjectId?: number;
    Search?: string;
    TypeDocument?: string;
    From?: string;
    To?: string;
    RefundTypeId?: number;
    Status?: InvoiceStatus;
  }): Observable<StrictHttpResponse<Array<InvoiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('ProjectId', params.ProjectId, {});
      rb.query('Search', params.Search, {});
      rb.query('TypeDocument', params.TypeDocument, {});
      rb.query('From', params.From, {});
      rb.query('To', params.To, {});
      rb.query('RefundTypeId', params.RefundTypeId, {});
      rb.query('Status', params.Status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    CustomerId?: number;
    ProjectId?: number;
    Search?: string;
    TypeDocument?: string;
    From?: string;
    To?: string;
    RefundTypeId?: number;
    Status?: InvoiceStatus;
  }): Observable<Array<InvoiceDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceDto>>) => r.body as Array<InvoiceDto>)
    );
  }

  /**
   * Path part for operation invoiceCreate
   */
  static readonly InvoiceCreatePath = '/api/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: {
    body?: InvoiceCreationDto
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: {
    body?: InvoiceCreationDto
  }): Observable<InvoiceDto> {

    return this.create$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: {
    body?: InvoiceCreationDto
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: {
    body?: InvoiceCreationDto
  }): Observable<InvoiceDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * Path part for operation invoiceGetById
   */
  static readonly InvoiceGetByIdPath = '/api/invoices/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: {
    id: number;
  }): Observable<InvoiceDto> {

    return this.getById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: {
    id: number;
  }): Observable<InvoiceDto> {

    return this.getById$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * Path part for operation invoiceCreateFromXml
   */
  static readonly InvoiceCreateFromXmlPath = '/api/invoices/xml';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFromXML$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFromXML$Plain$Response(params?: {
    body?: {
'invoices'?: Array<Blob>;
'projectId': number;
'categoryId'?: number;
}
  }): Observable<StrictHttpResponse<Array<InvoiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceCreateFromXmlPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFromXML$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFromXML$Plain(params?: {
    body?: {
'invoices'?: Array<Blob>;
'projectId': number;
'categoryId'?: number;
}
  }): Observable<Array<InvoiceDto>> {

    return this.createFromXML$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceDto>>) => r.body as Array<InvoiceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFromXML()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFromXML$Response(params?: {
    body?: {
'invoices'?: Array<Blob>;
'projectId': number;
'categoryId'?: number;
}
  }): Observable<StrictHttpResponse<Array<InvoiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceCreateFromXmlPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFromXML$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFromXML(params?: {
    body?: {
'invoices'?: Array<Blob>;
'projectId': number;
'categoryId'?: number;
}
  }): Observable<Array<InvoiceDto>> {

    return this.createFromXML$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceDto>>) => r.body as Array<InvoiceDto>)
    );
  }

  /**
   * Path part for operation invoiceUpdateById
   */
  static readonly InvoiceUpdateByIdPath = '/api/invoices/{invoiceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain$Response(params: {
    invoiceId: number;
    body?: InvoiceUpdateDto
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceUpdateByIdPath, 'put');
    if (params) {
      rb.path('invoiceId', params.invoiceId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain(params: {
    invoiceId: number;
    body?: InvoiceUpdateDto
  }): Observable<InvoiceDto> {

    return this.updateById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Response(params: {
    invoiceId: number;
    body?: InvoiceUpdateDto
  }): Observable<StrictHttpResponse<InvoiceDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceUpdateByIdPath, 'put');
    if (params) {
      rb.path('invoiceId', params.invoiceId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById(params: {
    invoiceId: number;
    body?: InvoiceUpdateDto
  }): Observable<InvoiceDto> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDto>) => r.body as InvoiceDto)
    );
  }

  /**
   * Path part for operation invoiceDeleteById
   */
  static readonly InvoiceDeleteByIdPath = '/api/invoices/{invoiceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    invoiceId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('invoiceId', params.invoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    invoiceId: number;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation invoiceExists
   */
  static readonly InvoiceExistsPath = '/api/invoices/Exists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exists$Plain$Response(params?: {
    body?: {
'date'?: string;
'number'?: string;
'supplier'?: string;
'projectId'?: number;
'invoiceId'?: number;
}
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceExistsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exists$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exists$Plain(params?: {
    body?: {
'date'?: string;
'number'?: string;
'supplier'?: string;
'projectId'?: number;
'invoiceId'?: number;
}
  }): Observable<ProjectDto> {

    return this.exists$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exists$Response(params?: {
    body?: {
'date'?: string;
'number'?: string;
'supplier'?: string;
'projectId'?: number;
'invoiceId'?: number;
}
  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceExistsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exists(params?: {
    body?: {
'date'?: string;
'number'?: string;
'supplier'?: string;
'projectId'?: number;
'invoiceId'?: number;
}
  }): Observable<ProjectDto> {

    return this.exists$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation invoiceUpdateInvoiceLines
   */
  static readonly InvoiceUpdateInvoiceLinesPath = '/api/invoices/{invoiceId}/invoiceLines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvoiceLines()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceLines$Response(params: {
    invoiceId: number;
    body?: Array<BaseInvoiceLineCreationDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceUpdateInvoiceLinesPath, 'put');
    if (params) {
      rb.path('invoiceId', params.invoiceId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateInvoiceLines$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceLines(params: {
    invoiceId: number;
    body?: Array<BaseInvoiceLineCreationDto>
  }): Observable<void> {

    return this.updateInvoiceLines$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation invoiceUpdateInvoiceStatus
   */
  static readonly InvoiceUpdateInvoiceStatusPath = '/api/invoices/{invoiceId}/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvoiceStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceStatus$Response(params: {
    invoiceId: number;
    body?: InvoiceStatusUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvoiceService.InvoiceUpdateInvoiceStatusPath, 'patch');
    if (params) {
      rb.path('invoiceId', params.invoiceId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateInvoiceStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceStatus(params: {
    invoiceId: number;
    body?: InvoiceStatusUpdateDto
  }): Observable<void> {

    return this.updateInvoiceStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
