/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectSheetDto } from '../models/project-sheet-dto';
import { ProjectSheetUpsertDto } from '../models/project-sheet-upsert-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectSheetService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectSheetGet
   */
  static readonly ProjectSheetGetPath = '/api/projectSheets/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params?: {
    projectId?: number;
    employeeId?: number;
  }): Observable<StrictHttpResponse<Array<ProjectSheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectSheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params?: {
    projectId?: number;
    employeeId?: number;
  }): Observable<Array<ProjectSheetDto>> {

    return this.get$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectSheetDto>>) => r.body as Array<ProjectSheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: {
    projectId?: number;
    employeeId?: number;
  }): Observable<StrictHttpResponse<Array<ProjectSheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectSheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: {
    projectId?: number;
    employeeId?: number;
  }): Observable<Array<ProjectSheetDto>> {

    return this.get$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectSheetDto>>) => r.body as Array<ProjectSheetDto>)
    );
  }

  /**
   * Path part for operation projectSheetGetWithOr
   */
  static readonly ProjectSheetGetWithOrPath = '/api/projectSheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWithOr$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithOr$Plain$Response(params?: {
    projectId?: number;
    employeeId?: number;
    orId?: number;
  }): Observable<StrictHttpResponse<ProjectSheetDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetGetWithOrPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('employeeId', params.employeeId, {});
      rb.query('orId', params.orId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectSheetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWithOr$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithOr$Plain(params?: {
    projectId?: number;
    employeeId?: number;
    orId?: number;
  }): Observable<ProjectSheetDto> {

    return this.getWithOr$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectSheetDto>) => r.body as ProjectSheetDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWithOr()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithOr$Response(params?: {
    projectId?: number;
    employeeId?: number;
    orId?: number;
  }): Observable<StrictHttpResponse<ProjectSheetDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetGetWithOrPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('employeeId', params.employeeId, {});
      rb.query('orId', params.orId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectSheetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWithOr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithOr(params?: {
    projectId?: number;
    employeeId?: number;
    orId?: number;
  }): Observable<ProjectSheetDto> {

    return this.getWithOr$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectSheetDto>) => r.body as ProjectSheetDto)
    );
  }

  /**
   * Path part for operation projectSheetSaveAndCalculate
   */
  static readonly ProjectSheetSaveAndCalculatePath = '/api/projectSheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAndCalculate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAndCalculate$Plain$Response(params?: {
    body?: Array<ProjectSheetUpsertDto>
  }): Observable<StrictHttpResponse<Array<ProjectSheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetSaveAndCalculatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectSheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveAndCalculate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAndCalculate$Plain(params?: {
    body?: Array<ProjectSheetUpsertDto>
  }): Observable<Array<ProjectSheetDto>> {

    return this.saveAndCalculate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectSheetDto>>) => r.body as Array<ProjectSheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAndCalculate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAndCalculate$Response(params?: {
    body?: Array<ProjectSheetUpsertDto>
  }): Observable<StrictHttpResponse<Array<ProjectSheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetSaveAndCalculatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectSheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveAndCalculate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAndCalculate(params?: {
    body?: Array<ProjectSheetUpsertDto>
  }): Observable<Array<ProjectSheetDto>> {

    return this.saveAndCalculate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectSheetDto>>) => r.body as Array<ProjectSheetDto>)
    );
  }

  /**
   * Path part for operation projectSheetDeleteById
   */
  static readonly ProjectSheetDeleteByIdPath = '/api/projectSheets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectSheetService.ProjectSheetDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: number;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
