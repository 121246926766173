import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { MenuToggleModule } from "./components";
import { MenuItems } from "./components/menu/menu-items/menu-items";
import { EnsureModuleLoadedOnceGuard } from "./guards";

import * as COMP from "./components";
import * as GUARDS from "src/app/core/guards";
import * as PAGES from "./pages";
import { RoleResolverService, RoleService } from "./roles";

const DECLARATIONS = [
  PAGES.AppComponent,
  PAGES.LoginComponent,
  PAGES.HomeComponent,
  COMP.FooterComponent,
  COMP.SideBarComponent,
  COMP.UserMenuComponent,
  COMP.NotificationsMenuComponent,
  COMP.NotFoundComponent,
  COMP.ConfirmDialogComponent
];

@NgModule({
  imports: [RouterModule, SharedModule, MenuToggleModule],
  exports: [SharedModule, ...DECLARATIONS, MenuToggleModule],
  declarations: DECLARATIONS,
  providers: [
    RoleResolverService,
    RoleService,
    GUARDS.CanDeactivateGuard,
    GUARDS.AuthGuard,
    MenuItems
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
