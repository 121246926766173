<bss-login *ngIf="tokenDto === null; else notLoggedIn"></bss-login>
<ng-template #notLoggedIn>
  <div fxLayout="row" fxLayoutAlign="center start" class="primary-color">
    <h1>Benvenuto, {{tokenDto.user.name}} {{tokenDto.user.surname}}.</h1>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="statsData">
    <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100" *ngIf="flammeuser">
      <mat-card class="mat-card primary-back">
        <div mat-card-widget>
          <button mat-mini-fab class="inline-block gene-fab-flat-icon" mat-card-icon [routerLink]="['customers']">
            <mat-icon>account_box</mat-icon>
          </button>
          <div class="inline-block vertical-align-mid pad-wrap">
            <h5 mat-card-widget-title class="mrgn-b-none">N° Clienti</h5>
            <h5>{{statsData.totalCustomer}}</h5>
          </div>
        </div>
      </mat-card>
      <mat-card class="mat-card primary-back">
        <div mat-card-widget>
          <button mat-mini-fab class="inline-block gene-fab-flat-icon" mat-card-icon [routerLink]="['invoices']">
            <mat-icon>ballot</mat-icon>
          </button>
          <div class="inline-block vertical-align-mid pad-wrap">
            <h5 mat-card-widget-title class="mrgn-b-none">N° fatture</h5>
            <h5>{{statsData.totalInvoice}}</h5>
          </div>
        </div>
      </mat-card>
    </div>
    <div fxFlex.gt-sm="60" fxFlex.gt-xs="100" fxFlex="100">
      <mat-card *ngIf="statsData && flammeuser">
        <div class="gene-card-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
              <h4>Dati {{statsData.year}}</h4>
            </div>
            <span fxFlex></span>
            <button mat-icon-button (click)="getStats(statsData.year-1)">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button (click)="getStats(statsData.year+1)">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div style="height: 300px;" echarts [options]="chartOption" class="demo-chart"></div>
      </mat-card>
    </div>
    <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
      <mat-card>
        <div class="gene-card-title">
          Template
          <mat-divider></mat-divider>
        </div>
        <div style="resize: auto;">
          <button mat-button class="download primary-back flex-wrap" (click)="download('Metodo Calcolo Costo Orario.pdf')" fxLayout="row">
            <mat-icon>insert_drive_file</mat-icon>&nbsp;&nbsp;Metodo Calcolo Costo Orario
          </button>
          <button mat-button class="download primary-back" (click)="download('Calcolo Monte Ore Annuo.xlsx')" fxLayout="row">
            <mat-icon>insert_drive_file</mat-icon>&nbsp;&nbsp;Calcolo Monte Ore Annuo
          </button>
          <button mat-button class="download primary-back" (click)="download('Costo Del Lavoro.xlsx')" fxLayout="row">
            <mat-icon>insert_drive_file</mat-icon>&nbsp;&nbsp;Costo del lavoro
          </button>
          <button mat-button class="download primary-back" (click)="download('Statino.xlsx')">
            <mat-icon>insert_drive_file</mat-icon>&nbsp;&nbsp;Statino
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
