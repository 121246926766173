import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenCreationDto, TokenDto } from 'src/app/FlammeServices/models';
import { TokenService } from 'src/app/FlammeServices/services';
import { NotificationService } from "src/app/services/notification.service";
import { SessionService } from 'src/app/services/session.service';
import { ToastType } from "src/app/shared/enums/toast-type.enum";

@Component({
  selector: "bss-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  email: string = "";
  password: string = "";

  loading: boolean = false;

  constructor(public tokenService: TokenService,
    private sessionService: SessionService,
    public notificationService: NotificationService,
    public router: Router) {
  }

  ngOnInit(): void {
  }

  login() {
    const tokenCreationDto: TokenCreationDto = { email: this.email, password: this.password };
    this.loading = true;
    this.tokenService.createToken({ body: tokenCreationDto })
      .subscribe((tokenDto: TokenDto) => {
        this.sessionService.set(tokenDto);
        this.notificationService.toast("Autenticato con successo", ToastType.success, "Autenticazione");
        this.loading = false;
      },
        (error) => {
          this.notificationService.toast("Autenticazione fallita", ToastType.error, "Autenticazione");
          this.loading = false;
        }
      );
  }
}
