<div class="gene-login gene-session pad-y-xl">
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxLayout="column" fxFlex.xl="540px" fxFlex.lg="540px" fxFlex.md="540px" fxFlex.sm="540px" fxFlex.xs="100">
      <mat-card class="gene-login-wrapper">
        <div>
          <div fxLayout="column" fxLayoutAlign="center center mrgn-b-md">
            <div class="login-logo justify-content">
              <img src="../../../../assets/img/logo.png" style="width: 200px; margin: 40px;">
            </div>
          </div>
          <form #form="ngForm">
            <div fxLayout="column" fxLayoutAlign="start stretch">
              <mat-form-field class="full-wid mrgn-b-md">
                <mat-placeholder class="placeholder">Utente</mat-placeholder>
                <input matInput type="text" name="email" required [(ngModel)]="email">
              </mat-form-field>
              <mat-form-field class="full-wid mrgn-b-md ">
                <mat-placeholder class="placeholder">Password</mat-placeholder>
                <input matInput type="password" name="password" required [(ngModel)]="password">
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex></span>
              </div>
              <button (click)="login()" class="loginbtn" mat-raised-button
                      [disabled]="!form.valid || loading">Accedi
              </button>
            </div>
          </form>
        </div>
      </mat-card>
    </div>
  </div>
</div>
