import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationLifeCycleEnum, NotificationStatusEnum } from 'enums';
import { Subscription } from 'rxjs/internal/Subscription';
import { ServerNotification } from 'src/app/models';

@Component({
  selector: 'bss-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {
  notifications: ServerNotification[] = [];

  LifeCycleEnum = NotificationLifeCycleEnum;
  StatusEnum = NotificationStatusEnum;

  private _subscriptions: Subscription[] = [];

  constructor() {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._subscriptions.every((s) => s.unsubscribe());
  }
  async markAsRead(guid: string, $event: any) { }

  async markAllAsRead() {}
}
