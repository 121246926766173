import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { StatsDto, TokenDto } from 'src/app/FlammeServices/models';
import { StatsService } from 'src/app/FlammeServices/services';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: "bss-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy {
  tokenDto: TokenDto = null;
  statsData: StatsDto = null;
  chartOption: EChartsOption;
  subs: Subscription[] = [];
  flammeuser = false;
  proj = 0;
  cust = 0;

  constructor(
    public sessionService: SessionService,
    public statsService: StatsService,
    public router: Router,
  ) { }

  ngOnInit(): void
  {
    this.subs.push(
      this.sessionService.currentUser$.subscribe((tokenDto) => {
        const login = !this.tokenDto && !!tokenDto;
        this.tokenDto = tokenDto;

        if(this.tokenDto && this.tokenDto.user)
        {
          this.flammeuser = ((this.tokenDto.user.role==1 || this.tokenDto.user.role==2))
          this.cust = this.tokenDto.user.idRoleCustomer;
          this.proj = this.tokenDto.user.idRoleProject;
        }


        if (this.tokenDto && this.flammeuser && login)
        {
          this.getStats(0);
        }
        else if(this.proj>0)
        {
          this.router.navigate(['/projects/project/' + this.proj + '/details']);
        }
        else if (this.cust>0)
        {
          this.router.navigate(['/projects']);
        }



      })
    );
  }

  getStats(year: number) {
    this.statsService.get({ year }).subscribe(x => {
      this.statsData = x;

      const options: EChartsOption = {
        legend: {
          show: true,
          orient: 'horizontal',
          left: 'left',
          // data: ['InseriteCount', 'ManagedCount']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Fatture inserite',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: 'bar',
            stack: 'A'
          },
          {
            name: 'Fatture classificate',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: 'bar',
            stack: 'A'
          }
        ]
      };

      for (let i = 0; i < 12; i++) {
        options.series[0].data[i] = x.statInvoices[i].managedCount;
        options.series[1].data[i] = x.statInvoices[i].insertedCount;
      }

      this.chartOption = options;
    });
  }

  download(file: string) {
    window.open('/assets/download/' + file, '_blank');
  }


  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

}
