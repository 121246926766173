<mat-card class="mrgn-b-none">
  <div class="footer-card-content pad-t-xs" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="mrgn-b-md">
      <div class="copyright-info">
        <div><b>Flamme</b> innovation advisory srl - © 2021 - All rights reserved.</div>
      </div>
    </div>
    <div class="mrgn-b-md version">{{ version }}</div>
  </div>
</mat-card>
