// tslint:disable: template-cyclomatic-complexity
// tslint:disable: template-use-track-by-function
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from 'src/app/config';
import { CoreService } from 'src/app/services/core.service';
import { SessionService } from 'src/app/services/session.service';
import { ChildrenItems, Menu, MenuItems, Roles } from '../menu/menu-items/menu-items';

@Component({
  selector: 'bss-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  @Input() menuList: any;
  @Input() verticalMenuStatus: boolean;

  tokendto;

  get version(): string {
    return CONFIG.UI_VERSION;
  }

  constructor(
    public translate: TranslateService, 
    private router: Router, 
    public menuItems: MenuItems, 
    public sessionService: SessionService,
    public coreService: CoreService) 
    {
      this.sessionService.currentUser$.subscribe((tokenDto) => this.tokendto = tokenDto);
      this.sessionService.verifyToken();
    }

  async onClick() {
    await this.router.navigate(['/']);
    this.closeSidenav();
  }

  closeSidenav() {
    this.coreService.sidenavOpen = false;
  }

  getFilteredChildren(menu: Menu): ChildrenItems[] {
    if (!menu.children) return [];
  
    return menu.children.filter(child => this.hasRight(child));
  }

  hasRight(menuitem: Menu | ChildrenItems): boolean {
    const requestedRole = menuitem.requestedRole;
  
    if (requestedRole === Roles.All) {
      return true;
    }
  
    if (this.tokendto && this.tokendto.user) {
      const userRole = this.tokendto.user.role;
  
      if (userRole === Roles.Admin) {
        return true;
      }
  
      if (Array.isArray(requestedRole)) {
        return requestedRole.includes(userRole);
      }
      return userRole === requestedRole;
    }
  
    return false;
  }
}
