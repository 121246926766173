import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'bss-action-snack-bar',
  templateUrl: './action-snack-bar.html',
  styleUrls: ['./action-snack-bar.scss'],
})
export class ActionSnackBarComponent {
  snackBarRef: MatSnackBarRef<ActionSnackBarComponent>;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  dismiss(): void {
    this.data.onAction();
    this.snackBarRef.dismiss();
  }

  get hasAction(): boolean {
    return !!this.data.action;
  }
}
