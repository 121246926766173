<h2 *ngIf="!!data?.title" mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content>
  <div id="message" *ngIf="!!data?.message">{{data.message}}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button id="ok_button" *ngIf="data.ok" mat-button type="button" color="primary" (click)="dialogRef.close(true)">
    <span>{{ data.ok }}</span>
  </button>
  <button id="cancel_button" *ngIf="data.cancel" mat-button type="button" (click)="dialogRef.close(false)">
    <span>{{ data.cancel }}</span>
  </button>
</mat-dialog-actions>
