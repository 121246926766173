import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { TokenDto } from 'src/app/FlammeServices/models';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private sessionService: SessionService, private router: Router) {}
  
  hasAccess(allowedRoles: number[]): Observable<boolean> {
    return this.sessionService.currentUser$.pipe(
      map((token: TokenDto | null) => {
        if (token && allowedRoles.includes(token.user.role)) {
          return true;
        } else {
          console.error('Accesso negato: ruolo non autorizzato');
          return false;
        }
      })
    );
  }
}
