import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RoleService } from 'src/app/core/roles/role.service';

@Injectable({
  providedIn: 'root'
})
export class RoleResolverService implements Resolve<boolean> {
  constructor(private rolesService: RoleService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const allowedRoles = route.data.allowedRoles;
    return this.rolesService.hasAccess(allowedRoles).pipe(
      take(1),
      map(hasAccess => {
        if (!hasAccess) {
          this.router.navigate(['**']); 
          return false;
        }
        return true;
      })
    );
  }
}
