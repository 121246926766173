import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable, ReplaySubject, Subject, throwError } from "rxjs";
import { TokenCreationDto, TokenDto } from '../FlammeServices/models';

@Injectable({
  providedIn: "root"
})
export class SessionService
{
  private baseUrl = environment.baseUrl;

  private currentUserSource = new ReplaySubject<TokenDto>(1);
  public currentUser$ = this.currentUserSource.asObservable();

  private waitRefreshToken: Subject<TokenDto>;
  public get waitRefreshToken$(): Observable<TokenDto>
  {
    return this.waitRefreshToken?.asObservable();
  }

  public storageKey = "flamme-user";

  constructor(private http: HttpClient)
  {
  }

  set(tokenDto: TokenDto): void
  {
    localStorage.setItem(this.storageKey, JSON.stringify(tokenDto));
    this.currentUserSource.next(tokenDto);
    this.waitRefreshToken?.next(tokenDto);
    this.waitRefreshToken = null;
  }

  logout()
  {
    localStorage.removeItem(this.storageKey);
    this.currentUserSource.next(null);
  }

  verifyToken()
  {
    const user: TokenDto = JSON.parse(localStorage.getItem(this.storageKey));
    this.currentUserSource.next(user);
  }

  initWaitRefreshToken()
  {
    this.waitRefreshToken = new Subject<TokenDto>();
  }
}
