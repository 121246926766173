import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bss-import-errors-dialog',
  templateUrl: './import-errors.dialog.html',
  styleUrls: ['./import-errors.dialog.scss']
})
export class ImportErrorsDialog {
  get messages(): string[] {
    return this.data?.message?.split("\n") ?? [];
  }

  get title() {
    const errors = this.messages.slice();
    return errors.shift();
  }

  get errors(): string[] {
    const errors = this.messages.slice();
    errors.shift();
    return errors;
  }

  constructor(
    public dialogRef: MatDialogRef<ImportErrorsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
  ) { }
}
