/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RefundTypeCreationDto } from '../models/refund-type-creation-dto';
import { RefundTypeDto } from '../models/refund-type-dto';
import { RefundTypeUpdateDto } from '../models/refund-type-update-dto';

@Injectable({
  providedIn: 'root',
})
export class RefundTypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation refundTypeGetAll
   */
  static readonly RefundTypeGetAllPath = '/api/refundType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<Array<RefundTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeGetAllPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RefundTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    projectId?: number;
  }): Observable<Array<RefundTypeDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RefundTypeDto>>) => r.body as Array<RefundTypeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<Array<RefundTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeGetAllPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RefundTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    projectId?: number;
  }): Observable<Array<RefundTypeDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RefundTypeDto>>) => r.body as Array<RefundTypeDto>)
    );
  }

  /**
   * Path part for operation refundTypeCreate
   */
  static readonly RefundTypeCreatePath = '/api/refundType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: {
    body?: RefundTypeCreationDto
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: {
    body?: RefundTypeCreationDto
  }): Observable<RefundTypeDto> {

    return this.create$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: {
    body?: RefundTypeCreationDto
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: {
    body?: RefundTypeCreationDto
  }): Observable<RefundTypeDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * Path part for operation refundTypeGetById
   */
  static readonly RefundTypeGetByIdPath = '/api/refundType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: {
    id: number;
  }): Observable<RefundTypeDto> {

    return this.getById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: {
    id: number;
  }): Observable<RefundTypeDto> {

    return this.getById$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * Path part for operation refundTypeUpdateById
   */
  static readonly RefundTypeUpdateByIdPath = '/api/refundType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain$Response(params: {
    id: number;
    body?: RefundTypeUpdateDto
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeUpdateByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Plain(params: {
    id: number;
    body?: RefundTypeUpdateDto
  }): Observable<RefundTypeDto> {

    return this.updateById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Response(params: {
    id: number;
    body?: RefundTypeUpdateDto
  }): Observable<StrictHttpResponse<RefundTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeUpdateByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RefundTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById(params: {
    id: number;
    body?: RefundTypeUpdateDto
  }): Observable<RefundTypeDto> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<RefundTypeDto>) => r.body as RefundTypeDto)
    );
  }

  /**
   * Path part for operation refundTypeDeleteById
   */
  static readonly RefundTypeDeleteByIdPath = '/api/refundType/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RefundTypeService.RefundTypeDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: number;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
