import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { SessionService } from "../services/session.service";
import { JL } from 'jsnlog';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.sessionService.currentUser$
      .pipe(
        take(1),
        switchMap((user) => {
          JL().info("tokenf ");
          if (user) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${user.token}`
              }
            });
          }

          return next.handle(request);
        }),
      );
  }
}
