import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationProviderService {
  languageChanged = new EventEmitter();

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['it']);
    this.translate.onLangChange.subscribe(() => this.languageChanged.emit());
  }

  public setLanguage(lang: string): any {
    return this.translate.use(lang);
  }

  async getString(key: string | string[], interpolateParams?: object): Promise<string> {
    return await this.translate.get(key, interpolateParams).toPromise();
  }

  getStringInstant(key: string | string[], interpolateParams?: object) {
    return this.translate.instant(key, interpolateParams);
  }

  public getCurrentLanguage() {
    if (!this.translate.currentLang) {
      this.translate.use('it');
    }
    return this.translate.currentLang;
  }
}
