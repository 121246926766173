/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SheetDto } from '../models/sheet-dto';
import { SheetEmployeeYearSummary } from '../models/sheet-employee-year-summary';
import { SheetUpsertDto } from '../models/sheet-upsert-dto';

@Injectable({
  providedIn: 'root',
})
export class SheetService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sheetGetAll
   */
  static readonly SheetGetAllPath = '/api/sheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    CustomerId?: number;
    Year?: number;
    Month?: number;
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Year', params.Year, {});
      rb.query('Month', params.Month, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    CustomerId?: number;
    Year?: number;
    Month?: number;
  }): Observable<Array<SheetDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    CustomerId?: number;
    Year?: number;
    Month?: number;
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Year', params.Year, {});
      rb.query('Month', params.Month, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    CustomerId?: number;
    Year?: number;
    Month?: number;
  }): Observable<Array<SheetDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * Path part for operation sheetUpsert
   */
  static readonly SheetUpsertPath = '/api/sheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsert$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsert$Plain$Response(params?: {
    body?: Array<SheetUpsertDto>
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetUpsertPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upsert$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsert$Plain(params?: {
    body?: Array<SheetUpsertDto>
  }): Observable<Array<SheetDto>> {

    return this.upsert$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsert()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsert$Response(params?: {
    body?: Array<SheetUpsertDto>
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetUpsertPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upsert$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsert(params?: {
    body?: Array<SheetUpsertDto>
  }): Observable<Array<SheetDto>> {

    return this.upsert$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * Path part for operation sheetGetByEmployeeAndYear
   */
  static readonly SheetGetByEmployeeAndYearPath = '/api/sheets/Employee/{employeeId}/Year/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByEmployeeAndYear$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByEmployeeAndYear$Plain$Response(params: {
    employeeId: number;
    year: number;
  }): Observable<StrictHttpResponse<SheetEmployeeYearSummary>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetGetByEmployeeAndYearPath, 'get');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SheetEmployeeYearSummary>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByEmployeeAndYear$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByEmployeeAndYear$Plain(params: {
    employeeId: number;
    year: number;
  }): Observable<SheetEmployeeYearSummary> {

    return this.getByEmployeeAndYear$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SheetEmployeeYearSummary>) => r.body as SheetEmployeeYearSummary)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByEmployeeAndYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByEmployeeAndYear$Response(params: {
    employeeId: number;
    year: number;
  }): Observable<StrictHttpResponse<SheetEmployeeYearSummary>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetGetByEmployeeAndYearPath, 'get');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SheetEmployeeYearSummary>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByEmployeeAndYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByEmployeeAndYear(params: {
    employeeId: number;
    year: number;
  }): Observable<SheetEmployeeYearSummary> {

    return this.getByEmployeeAndYear$Response(params).pipe(
      map((r: StrictHttpResponse<SheetEmployeeYearSummary>) => r.body as SheetEmployeeYearSummary)
    );
  }

  /**
   * Path part for operation sheetBulkCreateFromXls
   */
  static readonly SheetBulkCreateFromXlsPath = '/api/sheets/xls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateFromXls$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromXls$Plain$Response(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetBulkCreateFromXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreateFromXls$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromXls$Plain(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<Array<SheetDto>> {

    return this.bulkCreateFromXls$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateFromXls()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromXls$Response(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetBulkCreateFromXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreateFromXls$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromXls(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<Array<SheetDto>> {

    return this.bulkCreateFromXls$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * Path part for operation sheetBulkCreateFromTemporaryPresencesXls
   */
  static readonly SheetBulkCreateFromTemporaryPresencesXlsPath = '/api/sheets/temporary-presences-xls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateFromTemporaryPresencesXls$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromTemporaryPresencesXls$Plain$Response(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetBulkCreateFromTemporaryPresencesXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreateFromTemporaryPresencesXls$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromTemporaryPresencesXls$Plain(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<Array<SheetDto>> {

    return this.bulkCreateFromTemporaryPresencesXls$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateFromTemporaryPresencesXls()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromTemporaryPresencesXls$Response(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<StrictHttpResponse<Array<SheetDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SheetService.SheetBulkCreateFromTemporaryPresencesXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SheetDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreateFromTemporaryPresencesXls$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkCreateFromTemporaryPresencesXls(params?: {
    body?: {
'sheets': Blob;
'customerId': number;
}
  }): Observable<Array<SheetDto>> {

    return this.bulkCreateFromTemporaryPresencesXls$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SheetDto>>) => r.body as Array<SheetDto>)
    );
  }

}
