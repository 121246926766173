<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
  {{ title }}
  <ul>
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="dialogRef.close()">Chiudi</button>
</mat-dialog-actions>

