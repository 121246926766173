<button class="user-button" mat-button [matMenuTriggerFor]="menu">
  <span fxLayout="row" fxLayoutAlign="center center">
    <mat-icon  style="color: white">account_circle</mat-icon>
  </span>
</button>

<mat-menu class="user-menu" #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>account_circle</mat-icon>
    <span>{{ 'USER_MENU.PROFILE' | translate }}</span>
  </button>
  <!-- <button mat-menu-item>
    <mat-icon>settings</mat-icon>
    <span>{{ 'USER_MENU.SETTINGS' | translate }}</span>
  </button>
  <button mat-menu-item>
    <mat-icon>help</mat-icon>
    <span>{{ 'USER_MENU.HELP' | translate }}</span>
  </button> -->
  <mat-divider></mat-divider>
  <button (click)="logoutFn()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'USER_MENU.LOGOUT' | translate }}</span>
  </button>
</mat-menu>
