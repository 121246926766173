import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bss-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: ['user-menu.component.scss'],
})
export class UserMenuComponent {
  @Output() readonly logout = new EventEmitter();

  logoutFn() {
    this.logout.emit();
  }
}
