import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActionSnackBarComponent } from 'src/app/shared/components/action-snack-bar/action-snack-bar';
import { MessageDialogComponent } from 'src/app/shared/components/message/message.dialog';
import { ToastType } from 'src/app/shared/enums';
import { TranslationProviderService } from './translation-provider.service';

export class Closable {
  isClosed: boolean;
  afterClosed: Observable<any>;

  constructor(
    private notification: ClosableNotification,
    private onClose?: () => any,
    private skipCheckOverlay = false
  ) {
    const afterClosed = (notification as any).afterClosed;
    if (afterClosed) {
      if (typeof afterClosed === 'function') this.afterClosed = afterClosed.bind(notification).call();
      else this.afterClosed = afterClosed;
    }
  }

  close() {
    if (this.isClosed) return;
    this.isClosed = true;
    if (this.onClose) this.onClose();
    if (this.notification.close) this.notification.close();
    if (this.notification.dismiss) this.notification.dismiss();
    if (!this.skipCheckOverlay) this.checkOverlay();
  }

  // temporary fix for angular material md-overlay-container bug
  checkOverlay() { }
}

interface ClosableNotification {
  close?: () => void;
  dismiss?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly toastrOptions: GlobalConfig;

  constructor(
    private dialog: MatDialog,
    private toaster: ToastrService,
    private snackBar: MatSnackBar,
    private tp: TranslationProviderService
  ) {
    this.toastrOptions = this.toaster.toastrConfig;
    this.toastrOptions.positionClass = 'toast-bottom-right';
  }

  set skipCheckOverlay(value: boolean) {
    this._skipCheckOverlay = value;
  }
  get skipCheckOverlay() {
    return this._skipCheckOverlay;
  }

  private _skipCheckOverlay: boolean;

  message({ title = '', message = '', color = 'primary', ok = '', cancel = '', isMonospace = false } = {}): Closable {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      role: 'dialog',
      data: {
        title,
        message,
        color,
        cancel,
        ok,
        isMonospace,
      },
    });

    return new Closable(dialogRef, null, this.skipCheckOverlay);
  }

  messageDialogCannotBeUndone = async () => this.message(await this.getDefaultCannotBeUndoneDialog());

  messageDialogUnsavedChanges = async () => this.message(await this.getDefaultUnsavedChangesDialog());

  private async getDefaultUnsavedChangesDialog() {
    return {
      title: await this.tp.getString(`COMMON.UNSAVEDCHANGES_TITLE`),
      message: await this.tp.getString(`COMMON.UNSAVEDCHANGES_MESSAGE`),
      ok: await this.tp.getString(`COMMON.UNSAVEDCHANGES_OK_BUTTON`),
      cancel: await this.tp.getString(`COMMON.UNSAVEDCHANGES_CANCEL_BUTTON`),
    };
  }
  private async getDefaultCannotBeUndoneDialog() {
    return {
      title: await this.tp.getString(`COMMON.DIALOG_UNDONE_TITLE`),
      message: await this.tp.getString(`COMMON.DIALOG_UNDONE_MESSAGE`),
      ok: await this.tp.getString(`COMMON.DIALOG_UNDONE_BTN_OK`),
      cancel: await this.tp.getString(`COMMON.DIALOG_UNDONE_BTN_CANCEL`),
    };
  }

  open<T>(component: ComponentType<T>, config: MatDialogConfig = {}, params?: { key: string; value: any }[]): Closable {
    if (params) {
      if (!config.data) config.data = {};
      params.forEach((element) => {
        config.data[element.key] = element.value;
      });
    }
    const dialogRef = this.dialog.open(component, config);
    return new Closable(dialogRef, null, this.skipCheckOverlay);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  toast(text: string | string[] | any, type?: ToastType, title?: string) {
    let body: string;
    if (typeof text === 'string') body = text;
    else if (Array.isArray(text)) body = text.slice(1).join('\n');
    else {
      body = Object.keys(text)
        .reduce<string[]>((prev: string[], key: string) => {
          prev.push(`${key}:${text[key]}`);
          return prev;
        }, [])
        .join('\n');
    }

    return this.toaster.show(
      body,
      title ? title : typeof text === 'string' ? type.charAt(6).toUpperCase() + type.slice(7) : text[0],
      this.toastrOptions,
      type ? type : ToastType.info
    );
  }

  snack(msg: string, action?: string, onAction?: () => any, onClose?: () => any, t?: number): Closable {
    const simpleSnackBarRef = this.openSnack(msg, action, onAction, onClose);
    if (t) {
      setTimeout(() => {
        simpleSnackBarRef.close();
      }, t);
    }
    return simpleSnackBarRef;
  }

  private openSnack(
    message: string,
    action = '',
    onAction?: () => any,
    onClose?: () => any,
    config: MatSnackBarConfig = {}
  ): Closable {
    config.announcementMessage = message;

    const simpleSnackBarRef = this.snackBar.openFromComponent(ActionSnackBarComponent, {
      data: {
        message,
        action,
        onAction,
      },
    });

    return new Closable(simpleSnackBarRef, onClose, this.skipCheckOverlay);
  }
}
