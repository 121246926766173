import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';

export class BreadcrumbItem {
  url: string;
  label: string;
  ref?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  constructor(private router: Router, private route: ActivatedRoute) { }

  getBreadcrumbItems(): BreadcrumbItem[] {
    const breadcrumbItems = [];
    this.createBreadcrumbItem(breadcrumbItems, this.route.snapshot)
    return breadcrumbItems;
  }

  private createBreadcrumbItem(breadcrumbItems: BreadcrumbItem[], snapshot: ActivatedRouteSnapshot) {
    const breadcrumb = snapshot?.data?.breadcrumb;
    if (breadcrumb?.label && !breadcrumb?.skip) {
      const label = typeof breadcrumb.label === 'function' ? breadcrumb.label(snapshot.data) : breadcrumb.label;
      if (label && !breadcrumbItems.find((b) => b.label == label)) {
        breadcrumbItems.push({
          label: label,
          url: this.getSnapshotFullUrl(snapshot),
          ref: breadcrumb.ref,
        });
      }
    }

    if (snapshot.children.length > 0) {
      this.createBreadcrumbItem(breadcrumbItems, snapshot.children[0]);
    }
  }

  private getSnapshotFullUrl(snapshot: ActivatedRouteSnapshot) {
    const url = '/' + snapshot.pathFromRoot
      .map(r => r.url)
      .reduce((acc, val) => acc.concat(val), [])
      .map(f => f.path)
      .join('/');
    
    const params = Object.keys(snapshot.queryParams)
      .map(key => `${key}=${snapshot.queryParams[key]}`)
      .join("&");
    
    return url + (params ? '?' + params : '');
  }
}