import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import * as COMP from './components';
import * as PIPES from './pipes';
import * as DIRECTIVES from './directives';
import { MaterialModule } from './material.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxEchartsModule } from 'ngx-echarts';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DECLARATIONS = [
  COMP.ActionSnackBarComponent,
  COMP.MessageDialogComponent,
  COMP.AutocompleteComponent,
  COMP.ImportErrorsDialog,
  COMP.DaterangepickerYearCustomHeader,
  PIPES.FilterPipe,
  DIRECTIVES.CdkDetailRowDirective,
];

const MODULES = [
  FlexLayoutModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  MaterialModule,
  FontAwesomeModule,
  PerfectScrollbarModule,
  LoadingBarRouterModule,
  NgxDatatableModule,
  MatCardModule,
  MatIconModule,
];

const PROVIDERS = [PIPES.FilterPipe];

@NgModule({
  imports: [
    ...MODULES,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NgxEchartsModule.forChild(),
  ],
  declarations: DECLARATIONS,
  providers: [...PROVIDERS],
  exports: [TranslateModule, NgxEchartsModule, ...DECLARATIONS, ...MODULES],
})
export class SharedModule {}
