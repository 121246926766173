export const environment = {
  production: true,
  environmentName: 'test',
  baseUrl: '',
  scopeUri: ['api://e6d2938a-7076-4876-8992-37d8ca7e64f0/cdiweb'],
  clienId: 'e6d2938a-7076-4876-8992-37d8ca7e64f0',
  authority: 'https://login.microsoftonline.com/49d02e9a-dea4-4192-b27f-c69d2771c351',
  redirectUri: 'https://localhost',
  postLogoutRedirectUri: 'https://localhost/#/logged-out',
};
