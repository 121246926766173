<div
  id="main-app"
  class="app sidebar-color-dark header-color-dark"
  [dir]="layout"
  [ngClass]="{
    'app-dark': dark,
    'collapsed-sidebar': coreService.collapseSidebar,
    'compact-sidebar': compactSidebar,
    'customizer-in': customizerIn
  }"
  [class.side-panel-opened]="coreService.sidenavOpen"
  [class.side-panel-closed]="!coreService.sidenavOpen"
>
  <mat-sidenav-container class="gene-container">
    <mat-sidenav #sidenav [(opened)]="coreService.sidenavOpen" class="sidebar-area">
      <bss-side-bar [menuList]="menuItems" [verticalMenuStatus]="true"></bss-side-bar>
    </mat-sidenav>
    <mat-sidenav
      #notificationsSidenav
      position="end"
      class="notifications-sidenav-content"
      mode="over"
      opened="false"
      hasBackdrop="true"
    >
      <bss-notifications></bss-notifications>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="gene-header-toolbar">
        <div class="gene-ham-icon" *ngIf="(sessionService.currentUser$ | async) != null">
          <button *ngIf="tokenDto && tokenDto.user && (tokenDto.user.role==1 || tokenDto.user.role==2 || tokenDto.user.role==3)" class="" mat-mini-fab style="background-color: white; color: #005549;" mat-card-icon
                  (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <span fxFlex>
          <div class="breadcrumb">
            <ul>
              <li *ngFor="let b of breadcrumbs">
                <a *ngIf="!b.ref" [routerLink]="projectUser ? null : b.url" [ngClass]="{'disabled-link': !projectUser}">
                  {{ b.label }}
                </a>
                <span *ngIf="b.ref">{{ b.label }}</span>
              </li>
            </ul>
          </div>
        </span>
        <div class="secondary-menu" *ngIf="(sessionService.currentUser$ | async) != null">
          {{username}}
          <bss-user-menu (logout)="logout()"></bss-user-menu>
          <!-- <button (click)="notificationsSidenav.toggle()" mat-icon-button class="overflow-visible">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon style="color: white"
                        class="notification-icon"
                        [matBadge]="notifications?.length"
                        [matBadgeHidden]="notifications?.length == 0">
                notifications
              </mat-icon>
            </div>
          </button> -->
        </div>
      </mat-toolbar>

      <div class="main-container gene-base-container">
        <div class="inner-container" fxLayout="row wrap">
          <div class="full-wid" fxFlex="100" class="fix-container">
            <router-outlet></router-outlet>
            <!--<ngx-loading-bar></ngx-loading-bar>-->
          </div>
          <div class="footer-wrap pad-t-none full-wid" fxFLex="100">
            <bss-footer></bss-footer>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
