import { HttpClient } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JL } from "jsnlog";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app.routing";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./core/pages";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpProviders } from './interceptors/interceptors';
import { ApiConfiguration } from './FlammeServices/api-configuration';
import { environment } from 'src/environments/environment';
import { NgxEchartsModule } from 'ngx-echarts';

const logLevel = JL.getAllLevel();
const consoleAppender = JL.createConsoleAppender("consoleAppender");
const ajaxAppender = JL.createAjaxAppender("ajaxAppender");
ajaxAppender.setOptions({
  bufferSize: 20,
  storeInBufferLevel: 1000,
  level: logLevel,
  sendWithBufferLevel: 6000,
  url: "/jsnlog.logger"
});

// Configure the JSNLog logging library.
// See http://jsnlog.com/Documentation/JSNLogJs
JL().setOptions({
  appenders: [ajaxAppender, consoleAppender],
  level: logLevel
});

JL().debug("App is starting...");

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  declarations: [],
  bootstrap: [AppComponent],
  providers: [
    HttpProviders,
    { provide: "JSNLOG", useValue: JL },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'DD/MM/YYYY' },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMM YYYY',
        },
      }
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(apiConfiguration: ApiConfiguration) {
    apiConfiguration.rootUrl = environment.baseUrl;
  }
}
