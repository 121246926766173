<div class="scroll">
  <mat-toolbar class="gene-header-toolbar">
    <h3>{{ 'NOTIFICATIONS.TITLE' | translate }}</h3>
    <button mat-raised-button (click)="markAllAsRead()" color="primary" class="float-right" style="margin-left: 10px">
      {{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}
    </button>
  </mat-toolbar>
  <mat-nav-list>
    <mat-list-item *ngFor="let n of notifications" (click)="markAsRead(n.guid, $event)">
      <mat-icon
        mat-list-avatar
        *ngIf="n.lifeCycle == LifeCycleEnum.new"
        [ngStyle]="{
          color:
            n.status == StatusEnum.info
              ? 'green'
              : n.status == StatusEnum.warning
              ? 'orange'
              : n.status == StatusEnum.critical
              ? 'red'
              : 'black'
        }"
      >
        lens
      </mat-icon>
      <mat-icon
        mat-list-avatar
        *ngIf="n.lifeCycle == LifeCycleEnum.read"
        [ngStyle]="{
          color:
            n.status == StatusEnum.info
              ? 'green'
              : n.status == StatusEnum.warning
              ? 'orange'
              : n.status == StatusEnum.critical
              ? 'red'
              : 'black'
        }"
      >
        panorama_fish_eye</mat-icon
      >
      <h4 mat-line>{{ n.title }}</h4>
      <p mat-line>{{ n.description }} - {{ n.timeStamp }}</p>
    </mat-list-item>
  </mat-nav-list>
</div>
