import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG } from 'src/app/config';

@Component({
  selector: 'bss-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  get version(): string {
    return CONFIG.UI_VERSION;
  }

  constructor(public router: Router) { }
}
