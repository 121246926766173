import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  collapseSidebar = false;
  collapseSidebarStatus: boolean;
  sidenavMode = 'side';
  sidenavOpen = false;

  constructor() {}
}
