/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectOrCreationDto } from '../models/project-or-creation-dto';
import { ProjectOrDto } from '../models/project-or-dto';
import { ProjectOrEditDto } from '../models/project-or-edit-dto';
import { ProjectOrTypeDto } from '../models/project-or-type-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectOrService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectOrGetAll
   */
  static readonly ProjectOrGetAllPath = '/api/ProjectOr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    ProjectId?: number;
  }): Observable<StrictHttpResponse<Array<ProjectOrDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrGetAllPath, 'get');
    if (params) {
      rb.query('ProjectId', params.ProjectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectOrDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    ProjectId?: number;
  }): Observable<Array<ProjectOrDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectOrDto>>) => r.body as Array<ProjectOrDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    ProjectId?: number;
  }): Observable<StrictHttpResponse<Array<ProjectOrDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrGetAllPath, 'get');
    if (params) {
      rb.query('ProjectId', params.ProjectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectOrDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    ProjectId?: number;
  }): Observable<Array<ProjectOrDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectOrDto>>) => r.body as Array<ProjectOrDto>)
    );
  }

  /**
   * Path part for operation projectOrCreate
   */
  static readonly ProjectOrCreatePath = '/api/ProjectOr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: {
    body?: ProjectOrCreationDto
  }): Observable<StrictHttpResponse<ProjectOrDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectOrDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: {
    body?: ProjectOrCreationDto
  }): Observable<ProjectOrDto> {

    return this.create$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectOrDto>) => r.body as ProjectOrDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: {
    body?: ProjectOrCreationDto
  }): Observable<StrictHttpResponse<ProjectOrDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectOrDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: {
    body?: ProjectOrCreationDto
  }): Observable<ProjectOrDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectOrDto>) => r.body as ProjectOrDto)
    );
  }

  /**
   * Path part for operation projectOrGetAllTypes
   */
  static readonly ProjectOrGetAllTypesPath = '/api/ProjectOr/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProjectOrTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrGetAllTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectOrTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes$Plain(params?: {
  }): Observable<Array<ProjectOrTypeDto>> {

    return this.getAllTypes$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectOrTypeDto>>) => r.body as Array<ProjectOrTypeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProjectOrTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrGetAllTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectOrTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes(params?: {
  }): Observable<Array<ProjectOrTypeDto>> {

    return this.getAllTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectOrTypeDto>>) => r.body as Array<ProjectOrTypeDto>)
    );
  }

  /**
   * Path part for operation projectOrUpdate
   */
  static readonly ProjectOrUpdatePath = '/api/ProjectOr/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Plain$Response(params: {
    id: number;
    body?: ProjectOrEditDto
  }): Observable<StrictHttpResponse<ProjectOrDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectOrDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Plain(params: {
    id: number;
    body?: ProjectOrEditDto
  }): Observable<ProjectOrDto> {

    return this.update$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectOrDto>) => r.body as ProjectOrDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params: {
    id: number;
    body?: ProjectOrEditDto
  }): Observable<StrictHttpResponse<ProjectOrDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectOrDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params: {
    id: number;
    body?: ProjectOrEditDto
  }): Observable<ProjectOrDto> {

    return this.update$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectOrDto>) => r.body as ProjectOrDto)
    );
  }

  /**
   * Path part for operation projectOrDelete
   */
  static readonly ProjectOrDeletePath = '/api/ProjectOr/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectOrService.ProjectOrDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {
    id: number;
  }): Observable<void> {

    return this.delete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
