import { Injectable } from '@angular/core';


export enum Roles
{
  Admin = 1,
  Operator = 2,
  Customer = 3,
  All
}

export interface ChildrenItems {
  target: string;
  name: string;
  type?: string;
  requestedRole: Roles | Roles[];
}

export interface Menu {
  target: string;
  name: string;
  type: string;
  label: string;
  icon: string;
  children?: ChildrenItems[];
  requestedRole: Roles | Roles[];
}

const MENUITEMS: Menu[] = [
  {
    target: '',
    name: 'Home',
    type: 'button',
    label: '', // 'New',
    icon: 'home', // 'explore',
    children: [],
    requestedRole: [Roles.Operator, Roles.Admin, Roles.Customer]
  },

  {
    target: 'customers',
    name: 'Clienti',
    type: 'sub',
    label: '', // 'New',
    icon: 'perm_contact_calendar', // 'explore',
    children: [
      { target: '', name: 'Anagrafica', requestedRole: [Roles.Admin, Roles.Operator, Roles.Customer] },
      { target: 'customer', name: 'Nuovo cliente', requestedRole: [Roles.Admin, Roles.Operator] },
    ],
    requestedRole: [Roles.Admin, Roles.Operator, Roles.Customer]
  },
  {
    target: 'projects',
    name: 'Progetti',
    type: 'sub',
    label: '',
    icon: 'book',
    children: [
      { target: '', name: 'Elenco', requestedRole: [Roles.Admin, Roles.Operator, Roles.Customer] },
      { target: 'project', name: 'Nuovo progetto', requestedRole: [Roles.Admin, Roles.Operator] },
    ],
    requestedRole: [Roles.Admin, Roles.Operator, Roles.Customer]
  },
  {
    target: 'settings',
    name: 'Impostazioni',
    type: 'sub',
    label: '', // 'New',
    icon: 'settings', // 'explore',
    children: [
      { target: 'users', name: 'Utenti', requestedRole: Roles.Admin },
      { target: 'categories-management', name: 'Categorie di progetto', requestedRole: Roles.Admin },
      { target: 'refundTypes', name: 'Spese ammissibili', requestedRole: Roles.Admin },
      { target: 'activities', name: 'Attività', requestedRole: Roles.Admin },
      { target: 'standard-hourly-cost', name: 'Costo orario standard', requestedRole: Roles.Admin },
    ],
    requestedRole: Roles.Admin
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu: any) {
    MENUITEMS.push(menu);
  }
}
