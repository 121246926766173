/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeCreationDto } from '../models/employee-creation-dto';
import { EmployeeDto } from '../models/employee-dto';
import { EmployeeUpdateDto } from '../models/employee-update-dto';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation employeeGetAll
   */
  static readonly EmployeeGetAllPath = '/api/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(params?: {
    CustomerId?: number;
    Year?: number;
    Search?: string;
    SerialNumbers?: Array<string>;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<StrictHttpResponse<Array<EmployeeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Year', params.Year, {});
      rb.query('Search', params.Search, {});
      rb.query('SerialNumbers', params.SerialNumbers, {});
      rb.query('Page', params.Page, {});
      rb.query('Size', params.Size, {});
      rb.query('Sort', params.Sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(params?: {
    CustomerId?: number;
    Year?: number;
    Search?: string;
    SerialNumbers?: Array<string>;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<Array<EmployeeDto>> {

    return this.getAll$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeDto>>) => r.body as Array<EmployeeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
    CustomerId?: number;
    Year?: number;
    Search?: string;
    SerialNumbers?: Array<string>;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<StrictHttpResponse<Array<EmployeeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeGetAllPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Year', params.Year, {});
      rb.query('Search', params.Search, {});
      rb.query('SerialNumbers', params.SerialNumbers, {});
      rb.query('Page', params.Page, {});
      rb.query('Size', params.Size, {});
      rb.query('Sort', params.Sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
    CustomerId?: number;
    Year?: number;
    Search?: string;
    SerialNumbers?: Array<string>;
    Page?: number;
    Size?: number;
    Sort?: string;
  }): Observable<Array<EmployeeDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeDto>>) => r.body as Array<EmployeeDto>)
    );
  }

  /**
   * Path part for operation employeeCreate
   */
  static readonly EmployeeCreatePath = '/api/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: {
    body?: EmployeeCreationDto
  }): Observable<StrictHttpResponse<EmployeeDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: {
    body?: EmployeeCreationDto
  }): Observable<EmployeeDto> {

    return this.create$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeDto>) => r.body as EmployeeDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: {
    body?: EmployeeCreationDto
  }): Observable<StrictHttpResponse<EmployeeDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: {
    body?: EmployeeCreationDto
  }): Observable<EmployeeDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeDto>) => r.body as EmployeeDto)
    );
  }

  /**
   * Path part for operation employeeGetById
   */
  static readonly EmployeeGetByIdPath = '/api/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: {
    employeeId: number;
  }): Observable<StrictHttpResponse<EmployeeDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeGetByIdPath, 'get');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: {
    employeeId: number;
  }): Observable<EmployeeDto> {

    return this.getById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeDto>) => r.body as EmployeeDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: {
    employeeId: number;
  }): Observable<StrictHttpResponse<EmployeeDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeGetByIdPath, 'get');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: {
    employeeId: number;
  }): Observable<EmployeeDto> {

    return this.getById$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeDto>) => r.body as EmployeeDto)
    );
  }

  /**
   * Path part for operation employeeUpdateById
   */
  static readonly EmployeeUpdateByIdPath = '/api/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById$Response(params: {
    employeeId: number;
    body?: EmployeeUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeUpdateByIdPath, 'put');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateById(params: {
    employeeId: number;
    body?: EmployeeUpdateDto
  }): Observable<void> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation employeeDeleteById
   */
  static readonly EmployeeDeleteByIdPath = '/api/employees/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    employeeId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeDeleteByIdPath, 'delete');
    if (params) {
      rb.path('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    employeeId: number;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation employeeBulkUpsertFromXls
   */
  static readonly EmployeeBulkUpsertFromXlsPath = '/api/employees/xls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpsertFromXls$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpsertFromXls$Plain$Response(params?: {
    body?: {
'employees': Blob;
'customerId': number;
'year'?: number;
}
  }): Observable<StrictHttpResponse<Array<EmployeeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeBulkUpsertFromXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpsertFromXls$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpsertFromXls$Plain(params?: {
    body?: {
'employees': Blob;
'customerId': number;
'year'?: number;
}
  }): Observable<Array<EmployeeDto>> {

    return this.bulkUpsertFromXls$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeDto>>) => r.body as Array<EmployeeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpsertFromXls()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpsertFromXls$Response(params?: {
    body?: {
'employees': Blob;
'customerId': number;
'year'?: number;
}
  }): Observable<StrictHttpResponse<Array<EmployeeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.EmployeeBulkUpsertFromXlsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpsertFromXls$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpsertFromXls(params?: {
    body?: {
'employees': Blob;
'customerId': number;
'year'?: number;
}
  }): Observable<Array<EmployeeDto>> {

    return this.bulkUpsertFromXls$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeDto>>) => r.body as Array<EmployeeDto>)
    );
  }

}
