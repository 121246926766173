import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./core/guards";
import { HomeComponent } from './core/pages';
import { NotFoundComponent } from './core/components';
import { RoleResolverService } from './core/roles/role-resolver.service';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customers',
        loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule),
        data: { breadcrumb: { label: "Anagrafica clienti" } }
      },
      {
        path: 'projects',
        loadChildren: () => import('./modules/projects/projects.module').then((m) => m.ProjectsModule),
        data: { breadcrumb: { label: "Elenco progetti" } }
      },
      {
        path: 'invoices',
        resolve: {access : RoleResolverService },
        loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
        data : {allowedRoles : [1,2]}
      },
      {
        path: 'employees',
        loadChildren: () => import('./modules/employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        path: 'sheets',
        loadChildren: () => import('./modules/sheets/sheets.module').then((m) => m.SheetsModule),
      },
      {
        path: 'settings',
        resolve : {access : RoleResolverService},
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        data: { breadcrumb: { label: "Impostazioni", ref: true }  , allowedRoles: [1]},
      },
    ],
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
