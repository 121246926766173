import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap, take } from 'rxjs/operators';
import { JL } from "jsnlog";
import { TokenDto } from '../FlammeServices/models';
import { TokenService } from '../FlammeServices/services';
import { NotificationService } from '../services/notification.service';
import { SessionService } from '../services/session.service';
import { ToastType } from '../shared/enums';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    private tokenService: TokenService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error && !error.error?.handled) {
          switch (error.status) {
            case 400:


              if (error.error.errors) {
                const modalStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modalStateErrors.push(error.error.errors[key]);
                  }
                }
                this.notificationService.toast(
                  modalStateErrors.flat().join("\n"),
                  ToastType.error,
                  error.status + " - " + error.statusText
                );
              } else if (error.error.message) {
                JL().error(error.error.message);
                this.notificationService.toast(error.error.message, ToastType.error, error.status);
              } else {
                JL().error(error.statusText);
                this.notificationService.toast(error.statusText, ToastType.error, error.status);
              }
              break;
            case 401:
              JL().error("Error 401 - Unauthorized");
              return this.sessionService.currentUser$
                .pipe(
                  take(1),
                  switchMap((user) => {
                    if (this.sessionService.waitRefreshToken$) {
                      return this.sessionService.waitRefreshToken$.pipe(
                        first(),
                        switchMap((tokenDto) => {
                          return this._retryRequest(tokenDto, request, next, error);
                        }),
                      );
                    } else if (user) {
                      this.sessionService.initWaitRefreshToken();
                      return this.tokenService.refreshToken({ body: { token: user.refreshToken } }).pipe(
                        catchError((_) => null),
                        switchMap((tokenDto: TokenDto | null) => {
                          this.sessionService.set(tokenDto);
                          return this._retryRequest(tokenDto, request, next, error);
                        })
                      );
                    }
                    return this._unauthorized(error);
                  })
                );
            case 403:
              return this._unauthorized(error);
            case 404:
              this.router.navigateByUrl("/not-found");
              break;
            default:
              break;
          }
        }
        return throwError(error);
      })
    );
  }

  private _retryRequest(tokenDto: TokenDto, request: HttpRequest<any>, next: HttpHandler, error: any): Observable<any> {
    if (tokenDto) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${tokenDto.token}`
        }
      });
      return next.handle(request);
    }
    return this._unauthorized(error);
  }

  private _unauthorized(error): Observable<any> {
    this.notificationService.toast("Non autorizzato.", ToastType.error, error.status);
    this.router.navigateByUrl("").then(() => this.sessionService.logout());
    return throwError(error);
  }
}
