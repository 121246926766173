/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentationSectionHeader } from '../models/documentation-section-header';
import { DocumentationSectionInsertDto } from '../models/documentation-section-insert-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentationSectionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentationSectionGetHeaders
   */
  static readonly DocumentationSectionGetHeadersPath = '/api/documentation/headers/{idProject}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHeaders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaders$Plain$Response(params: {
    idProject: number;
  }): Observable<StrictHttpResponse<Array<DocumentationSectionHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentationSectionService.DocumentationSectionGetHeadersPath, 'get');
    if (params) {
      rb.path('idProject', params.idProject, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentationSectionHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHeaders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaders$Plain(params: {
    idProject: number;
  }): Observable<Array<DocumentationSectionHeader>> {

    return this.getHeaders$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentationSectionHeader>>) => r.body as Array<DocumentationSectionHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHeaders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaders$Response(params: {
    idProject: number;
  }): Observable<StrictHttpResponse<Array<DocumentationSectionHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentationSectionService.DocumentationSectionGetHeadersPath, 'get');
    if (params) {
      rb.path('idProject', params.idProject, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentationSectionHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHeaders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaders(params: {
    idProject: number;
  }): Observable<Array<DocumentationSectionHeader>> {

    return this.getHeaders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentationSectionHeader>>) => r.body as Array<DocumentationSectionHeader>)
    );
  }

  /**
   * Path part for operation documentationSectionPost
   */
  static readonly DocumentationSectionPostPath = '/api/documentation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `post$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post$Plain$Response(params?: {
    body?: DocumentationSectionInsertDto
  }): Observable<StrictHttpResponse<DocumentationSectionHeader>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentationSectionService.DocumentationSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentationSectionHeader>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `post$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post$Plain(params?: {
    body?: DocumentationSectionInsertDto
  }): Observable<DocumentationSectionHeader> {

    return this.post$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentationSectionHeader>) => r.body as DocumentationSectionHeader)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `post()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post$Response(params?: {
    body?: DocumentationSectionInsertDto
  }): Observable<StrictHttpResponse<DocumentationSectionHeader>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentationSectionService.DocumentationSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentationSectionHeader>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `post$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post(params?: {
    body?: DocumentationSectionInsertDto
  }): Observable<DocumentationSectionHeader> {

    return this.post$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentationSectionHeader>) => r.body as DocumentationSectionHeader)
    );
  }

}
