import { Directive, HostListener, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Directive()
export abstract class CanDeactivateDirective {
  abstract canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;

  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateDirective> {
  canDeactivate(component: CanDeactivateDirective): boolean {
    if (component.canDeactivate()) {
      return confirm('You have unsaved changes! If you leave, your changes will be lost.');
    }
    return true;
  }
}
