import { Injectable } from '@angular/core';

import packagejson from '../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _settings: any = {};

  constructor() {
    this.defaults();
  }

  private defaults() {
    this._settings.useHttps = true;
    this._settings.logappenders = ['ajaxAppender'];
    this._settings.loglevel = 1000;
  }

  public get(key: any) {
    return this._settings[key];
  }

  public get appVersion(): string {
    return packagejson.version;
  }

  public get appName(): string {
    return packagejson.name;
  }
}
