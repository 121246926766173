<mat-form-field class="full-width"
  [ngClass]="{'mat-form-field-invalid': !ngControl.control.valid && ngControl.control.errors}">
  <input type="search" matInput autocomplete="off" [placeholder]="placeholder" [formControl]="autoCompleteControl"
    [matAutocomplete]="auto" [readonly]="readOnly || isDisabled" />
  <a class="clickable remove" (click)="cleanValue()" *ngIf="readOnly" [hidden]="isDisabled">
    <mat-icon>close</mat-icon>
  </a>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" (optionSelected)="onSelectionChanged($event)">
    <mat-option *ngIf="isLoading" class="items-loading">
      <mat-spinner diameter="50"></mat-spinner>
    </mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let i of items" [value]="i">
        {{ displayWith(i) }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
