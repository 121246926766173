import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { NotificationService } from "src/app/services/notification.service";
import { ServerNotification } from "../../../models";
import { SettingsService } from "../../../services/settings.service";
import { TranslationProviderService } from "../../../services/translation-provider.service";
import { MenuItems } from "../../components/menu/menu-items/menu-items";
import { CoreService } from "../../../services/core.service";
import { SessionService } from 'src/app/services/session.service';
import { BreadcrumbService, BreadcrumbItem } from 'src/app/services/breadcrumb.service';
import { TokenDto } from 'src/app/FlammeServices/models';
import { TokenService } from 'src/app/FlammeServices/services';

@Component({
  selector: "bss-app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // tslint:disable-next-line: use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    "(window:resize)": "onResize($event)"
  }
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("sidenav", { static: true }) sidenav;

  tokenDto: TokenDto = null;
  version = "";
  selectedLanguage = "";
  header: string;
  url: string;
  root: any = "ltr";
  layout: any = "ltr";
  notifications: ServerNotification[] = [];
  _subscriptions: Subscription[] = [];
  isMobile = false;
  dark: boolean;
  compactSidebar: boolean;
  username = "not logged";
  projectUser = undefined;
  isMobileStatus: boolean;
  customizerIn: boolean;

  get breadcrumbs(): BreadcrumbItem[] {
    return this.breadcrumbService.getBreadcrumbItems();
  }

  windowSize: number;
  constructor(
    public tp: TranslationProviderService,
    private settings: SettingsService,
    public tokenService: TokenService,
    public coreService: CoreService,
    private notify: NotificationService,
    public sessionService: SessionService,
    public menuItems: MenuItems,
    public translate: TranslateService,
    public router: Router,
    private breadcrumbService: BreadcrumbService,
  ) {
    if (settings.appName && settings.appVersion) this.version = `v${settings.appVersion}`;

    translate.use("it");
  }


  async ngOnInit() {
    this.sessionService.verifyToken();

    this.sessionService.currentUser$.subscribe((tokenDto) => {
      const login = !this.tokenDto && !!tokenDto;
      this.tokenDto = tokenDto;
      if (tokenDto && tokenDto.user){
        this.username = tokenDto.user.name + ' ' + tokenDto.user.surname;
        tokenDto.user.idRoleProject != undefined ? this.projectUser= tokenDto.user.idRoleProject : this.projectUser = undefined;
      }
    });

    this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;

    this.url = this.router.url;
    this.customizeSidebar();

    setTimeout(() => {
      this.windowSize = window.innerWidth;
      this.resizeSideBar();
    }, 0);

    this.selectedLanguage = this.tp.getCurrentLanguage();

  }

  ngOnDestroy(): void {
    this._subscriptions.every((s) => s.unsubscribe());
  }

  toggleSidebar() {
    this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
  }

  async logout() {
    this.sessionService.logout();
    await this.router.navigateByUrl("");
  }

  // onResize method is used to set the side bar according to window width.
  onResize(event) {
    this.windowSize = event.target.innerWidth;
    this.resizeSideBar();
  }

  // customizeSidebar method is used to change the side bar behaviour.
  customizeSidebar() {
    if (window.innerWidth < 1200) {
      this.coreService.sidenavMode = "over";
      this.coreService.sidenavOpen = false;
      const mainDiv = document.getElementsByClassName("app");
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < mainDiv.length; i++) {
        if (!mainDiv[i].classList.contains("sidebar-overlay")) {
          document.getElementById("main-app").className += " sidebar-overlay";
        }
      }
    }
  }

  // To resize the side bar according to window width.
  resizeSideBar() {
    if (this.windowSize < 1200) {
      this.isMobileStatus = true;
      this.isMobile = this.isMobileStatus;
      this.coreService.sidenavMode = "over";
      // for responsive
      const mainDiv = document.getElementsByClassName("app");
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < mainDiv.length; i++) {
        if (!mainDiv[i].classList.contains("sidebar-overlay")) {
          if (document.getElementById("main-app")) {
            document.getElementById("main-app").className += " sidebar-overlay";
          }
        }
      }
    } else {
      this.isMobileStatus = false;
      this.isMobile = this.isMobileStatus;
      this.coreService.sidenavMode = "side";
      // for responsive
      const mainDiv = document.getElementsByClassName("app");
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < mainDiv.length; i++) {
        if (mainDiv[i].classList.contains("sidebar-overlay")) {
          document.getElementById("main-app").classList.remove("sidebar-overlay");
        }
      }
    }
  }
}
