<div class="sidebar-panel gene-sidenav" fxLayout="column">
  <mat-toolbar class="sidebar-logo">
    <div class="gene-logo">
      <a href="javascript:void(0)" (click)="onClick()">
        <div class="logo-text inline-block" style="padding-top: 20px;">
          <img src="assets/img/logo.png" style="width: 150px"/>
        </div>
      </a>
    </div>
  </mat-toolbar>
  <div class="sidebar-content">
    <perfect-scrollbar class="p-scrollbar">
      <div class="sidebar-container">
        <div class="gene-user-thumb text-center">
          <!-- <img class="img-responsive img-circle" src="assets/img/default-user-icon-20.jpg" width="107" height="107" alt="user list image" /> -->
          <!-- <div class="gene-user-name">Nome Cognome</div> -->
        </div>
        <div class="sidenav-scrollbar-container" fxLayout="column">
          <ng-container *ngIf="verticalMenuStatus; else horizontalMenuStatus">

            <mat-nav-list fxLayout="column" bssMenuToggleDirective class="navigation">
              <mat-list-item bssMenuToggleLink *ngFor="let menuitem of menuList.getAll()">
                <ng-container *ngIf="hasRight(menuitem)">
                  <a bssMenuToggle
                     class="gene-relative"
                     mat-ripple
                     *ngIf="menuitem.type === 'button'"
                     routerLinkActive="active-link"
                     (click)="closeSidenav()"
                     [routerLink]="['/']">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate }}</span>
                    <ng-container *ngIf="menuitem.label">
                      <span class="sidebar-label">{{ menuitem.label }}</span>
                    </ng-container>
                  </a>
                  <a bssMenuToggle
                     class="gene-relative"
                     mat-ripple
                     *ngIf="menuitem.type === 'link'"
                     routerLinkActive="active-link"
                     (click)="closeSidenav()"
                     [routerLink]="['/', menuitem.target]">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate }}</span>
                    <ng-container *ngIf="menuitem.label">
                      <span class="sidebar-label">{{ menuitem.label }}</span>
                    </ng-container>
                  </a>
                  <span></span>
                  <a bssMenuToggle
                     class="gene-relative"
                     mat-ripple
                     *ngIf="menuitem.type === 'sub'">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate }}</span>
                    <ng-container *ngIf="menuitem.label">
                      <span class="sidebar-label">{{ menuitem.label }}</span>
                    </ng-container>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret" *ngIf="menuitem.children && menuitem.children.length > 0">chevron_right</mat-icon>
                  </a>
                  <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
                    <mat-list-item *ngFor="let childitem of getFilteredChildren(menuitem)" routerLinkActive="open">
                      <a (click)="closeSidenav()"
                         [routerLink]="['/' + menuitem.target + '/' + childitem.target]"
                         [queryParams]="childitem.params"
                         routerLinkActive="active-link"
                         class="gene-relative"
                         mat-ripple>
                        {{ childitem.name | translate }}
                        <ng-container *ngIf="childitem.label">
                          <span class="sidebar-label">{{ childitem.label }}</span>
                        </ng-container>
                      </a>
                    </mat-list-item>
                  </mat-nav-list>
                  <mat-divider class="mat-divider" *ngIf="menuitem.name === 'INSURANCES'"></mat-divider>
                </ng-container>
              </mat-list-item>
            </mat-nav-list>
            
          </ng-container>
          <ng-template #horizontalMenuStatus>
            <mat-nav-list fxLayout="column" bssMenuToggleDirective class="navigation">
              <mat-list-item *ngFor="let menuitem of menuList.getAll()" bssMenuToggleLink>
                <ng-container *ngIf="hasRight(menuitem)">
                  <a bssMenuToggle
                     class="gene-relative"
                     mat-ripple
                     *ngIf="menuitem.type === 'button'"
                     routerLinkActive="active-link"
                     (click)="closeSidenav()"
                     [routerLink]="['/dashboard/crm']">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate }}</span>
                    <ng-container *ngIf="menuitem.label">
                      <span class="sidebar-label">{{ menuitem.label }}</span>
                    </ng-container>
                  </a>
                  <a href="javascript:void(0)" *ngIf="menuitem.type === 'sub'">
                    <span class="gene-text-sm">{{ menuitem.name | translate }}</span>
                  </a>
                  <mat-nav-list *ngIf="menuitem.type === 'sub'">
                    <mat-list-item bssMenuToggleLink *ngFor="let childItems of menuitem.children"
                                   [ngClass]="{ open: childItems.isOpen }">
                      <a class="anglo-relative"
                         bssMenuToggle
                         routerLinkActive="active-link"
                         (click)="closeSidenav()"
                         [routerLink]="['/' + childItems.target]"
                         *ngIf="childItems.type == 'link'">
                        <mat-icon>{{ childItems.icon }}</mat-icon>
                        <span>{{ childItems.name | translate }}</span>
                      </a>
                      <a class="anglo-relative"
                         bssMenuToggle
                         href="javascript:void(0)"
                         *ngIf="childItems.type == 'subChild'">
                        <mat-icon>{{ childItems.icon }}</mat-icon>
                        <span>{{ childItems.name | translate }}</span>
                        <span fxFlex></span>
                        <mat-icon class="menu-caret">chevron_right</mat-icon>
                      </a>
                      <mat-nav-list class="sub-menu" *ngIf="childItems.type === 'subChild'">
                        <mat-list-item *ngFor="let subchilditem of childItems.children" routerLinkActive="open">
                          <a class="anglo-relative"
                             (click)="closeSidenav()"
                             [routerLink]="['/' + childItems.target + '/' + subchilditem.target]"
                             routerLinkActive="active-link">
                            <span>{{ subchilditem.name | translate }}</span>
                          </a>
                        </mat-list-item>
                      </mat-nav-list>
                    </mat-list-item>
                  </mat-nav-list>
                </ng-container>
              </mat-list-item>
            </mat-nav-list>
          </ng-template>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="version-container">
    <span class="version">{{ version }}</span>
  </div>
</div>
